import React from "react";
import styles from "./didYouKnow.module.css";

class PageTitle extends React.Component {
  render() {
    return (
      <div className={styles.bg}>
        <div className="grid grid-justifyCenter">
          <div className="grid-cell">
            <h3>Did You Know?</h3>
            <div className={styles.separator} />
            <h6>{this.props.fact}</h6>
            <small className={styles.small}>{this.props.source}</small>
          </div>
        </div>
      </div>
    );
  }
}
// className={`${styles.test1} ${styles.test2}`}
export default PageTitle;
