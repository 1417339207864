import React from "react";
import styles from "./pageTitle.module.css"

class PageTitle extends React.Component {
  render() {
    return (
      <div>
        <h1>{this.props.title}</h1>
        <div className={styles.box}><h3>Serving Baton Rouge and all of Louisiana</h3></div>
        <div className={styles.separator}></div>
      </div>
    );
  }
}
// className={`${styles.test1} ${styles.test2}`}
export default PageTitle;
