import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class CyberLiabilityInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Cyber Liability Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Cyber Liability Insurance (Data Breach Liability) is there to protect your Louisiana based company in the event of compromised data integrity and privacy. Contact us for a quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Company computer servers" />
        <Callout
          title="Cyber Liability Insurance"
          content="Every Louisiana based business, regardless of industry, has the responsibility to safeguard customer data. This can be in the form of personal identifying data such as addresses, phone numbers, social security numbers, and other company data. If there is a data breach, whether electronically or by paper, this can lead to immense financial damage and hurt your company’s reputation. Cyber Liability Insurance (Data Breach Liability) is there to protect your company in the event of compromised data integrity and privacy."
        >
          <div className="servicesVideoWrapper"><div className="videoWrapper"><iframe className="iframe services-video" width="560" height="315" src="https://www.youtube.com/embed/-yxZGoyztX8?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Cyber Liability Insurance: What You Need to Know" /></div></div>
        </Callout>
        <div className="site-body page-content">
          <h2>What Cyber Liability Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Liability.</strong> Covers the costs (e.g., legal fees, court judgements) incurred after a cyberattack, such as data theft, or the unintentional transmission of a computer virus to another party, causing them financial harm.</li>
          <li><strong>Crisis Management.</strong> Covers the cost of notifying consumers about a data breach that resulted in the release of private information, along with providing them credit monitoring services, as well as the cost of retaining a public relations firm, or launching an advertising campaign to rebuild a company’s reputation.</li>
          <li><strong>Directors & Officers (D&O)/Management Liability.</strong> Covers the cyber liability risks faced individually by a company’s key decision makers while acting on behalf of the company.</li>
          <li><strong>Business Interruption.</strong> Covers loss of income due to an attack on a company’s network that limits its ability to conduct business.</li>
          <li><strong>Cyber Extortion.</strong> Covers the “settlement” of an extortion threat against a company’s network, as well as the cost of hiring a security firm to track down the blackmailers.</li>
          <li><strong>Loss/Corruption of Data.</strong> Covers damage to, or destruction of, valuable information assets as a result of viruses, malicious code and Trojan horses.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="The average cost of a data breach for a business is $300,000. Additionally, 3 out of 4 incidents result from human error."
            source="IBM & Symantec"
          />
          <h2>What Cyber Liability Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Failure to Follow Protocols</strong> Some policies have open-ended exclusions that won’t cover data breaches that result form the inability to install timely security patches or failure to follow a company’s security policy.</li>
          <li><strong>War & Terrorism.</strong> Another exclusion in some policies is for war or terrorism. This will also not cover attacks from foreign countries with political, religious or social motivation.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Cyber Liability Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default CyberLiabilityInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "cyber-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-15.jpg" }) {
      ...mainBg
    }
  }
`;
