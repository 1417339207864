import React from "react";
import { Link } from "gatsby"
import PageTitle from "./pageTitle";

class Callout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div className="primary-bg">
        <div className="grid grid-justifyCenter">
          <div className="grid-cell ie-fix callout">
            <PageTitle title={this.props.title} />
            <p>{this.props.content}</p>            
            {children}
            <Link to={"/get-insurance/"} className="button button-secondary services-cta-button">Contact Us About {this.props.title}</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Callout;