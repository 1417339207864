import React from "react";
import Img from "gatsby-image";
import styles from "./mainBg.module.css";

class MainBg extends React.Component {
  render() {
    return (
      <div className={styles.main}>
        <Img
          title="Header Image"
          alt={ this.props.alt }
          imgStyle={ this.props.imgStyle }
          fluid={this.props.mainBg.childImageSharp.fluid}
          className={styles.mainbg}
        />
      </div>
    );
  }
}
// className={`${styles.test1} ${styles.test2}`}
export default MainBg;
